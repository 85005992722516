module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.zaunergroup.com/karriere/wp/graphql"},
    },{
      plugin: require('../node_modules/@perituswebdesign/gatsby-plugin-matomo-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"dataLayerName":"_mtm","domain":"cdn.matomo.cloud/zaunergroup.matomo.cloud","containerId":"ExcAWhtr","includeInDevelopment":false,"defaultDataLayer":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zauner Anlagentechnik GmbH","short_name":"zaunergroup","lang":"de","start_url":"/","display":"standalone","icon":"src/images/favicon-karriere.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e95a9c9dd0dcb45f2e46286222f2dd19"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
